import React from 'react';
import { inject, observer } from 'mobx-react';

import { SMALL_SCREEN_MEDIA } from '../../../services/constants';
import { GetQueryParamFromUrl } from '../../../services/url';

const base = 'terminal-header';

@inject('terminalMenuStore')
@inject('terminalCartStore')

@observer
export default class extends React.Component {
    selectUIView = (view) => {
        const {
            terminalMenuStore: { setItemsUIView },
        } = this.props;
        setItemsUIView(view);
    };

    handleShowSplash = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        let orderType = GetQueryParamFromUrl('orderType');
        terminalMenuStore.showSplashScreenActions(orderType);
        terminalCartStore.toggleCombo = false;
        terminalMenuStore.setCouponTxt('');
        terminalMenuStore.setCouponDisable(false);
        terminalMenuStore.setCouponErrMessage(null);
        terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
    };

    handleHelpBtnClick = () => {
        const {
            terminalMenuStore: { mobileScreens, setShowMobileScreen },
        } = this.props;
        setShowMobileScreen(mobileScreens.HELP);
    };

    render() {
        const {
            terminalMenuStore: {
                itemTypes,
                itemsUIView,
                terminalInfo,
                showSplashScreen,
                showLuckyWheelScreen,
                hubCode,
                showMobileScreen,
                mobileScreens,
            },
            onHeaderClick,
        } = this.props;
        const isSmallScreen = window.matchMedia(SMALL_SCREEN_MEDIA).matches;
        const isHelpScreenOpen = showMobileScreen === mobileScreens.HELP;
        const outletName = terminalInfo.data && terminalInfo.data.address.name;
        const notShowHeaderOnIpad = showSplashScreen || showLuckyWheelScreen;

        if (isSmallScreen) {
            return (
                <div className={base}>
                    <div className={`${base}__logo`}>
                        <img className={`${base}__logo_img`} src={process.env.PUBLIC_URL + '/ms-icon-150x150.png'} />
                    </div>
                    {outletName && (
                        <div className={`${base}__outlet_name_cont`}>
                            {outletName.includes('@') ? (
                                <p className={`${base}__outlet_name`}>
                                    {outletName.split('@')[0]}@ <br />
                                    {outletName.split('@')[1]}
                                </p>
                            ) : (
                                <p className={`${base}__outlet_name`}>
                                    Pop Meals@ <br />
                                    {outletName}
                                </p>
                            )}
                        </div>
                    )}

                    <img
                        onClick={this.handleHelpBtnClick}
                        className={`${base}__help_btn ${isHelpScreenOpen ? 'help-screen-open' : ''}`}
                        src="https://image.popcontent.link/help_icon.svg"
                        alt="help icon"
                    />
                </div>
            );
        }

        return (
            !notShowHeaderOnIpad && (
                <div className={`${base}`} id={base}>
                    <div className={`${base}__logo_start`}>
                        <div className={`${base}__logo`}>
                            <img
                                className={`${base}__logo_img`}
                                src={process.env.PUBLIC_URL + '/ms-icon-150x150.png'}
                            />
                        </div>
                        <button onClick={this.handleShowSplash} className={`${base}__start`}>
                            Start again
                        </button>
                    </div>
                    <div style={{ marginTop: '20px' }} className={`${base}__item_types`}>
                        <div
                            onClick={() => this.selectUIView(itemTypes.MAINS)}
                            className={`item_type ${itemsUIView === itemTypes.MAINS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                <span className="text-item">Mains</span>
                            </p>
                        </div>

                        <div
                            onClick={() => this.selectUIView(itemTypes.DEALS)}
                            className={`item_type ${itemsUIView === itemTypes.DEALS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                {' '}
                                <span className="text-item">Family Combos</span>
                            </p>
                        </div>

                        <div
                            onClick={() => this.selectUIView(itemTypes.SNACKS)}
                            className={`item_type ${itemsUIView === itemTypes.SNACKS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                {' '}
                                <span className="text-item">Snacks</span>
                            </p>
                        </div>
                        <div
                            onClick={() => this.selectUIView(itemTypes.DESSERTS)}
                            className={`item_type ${itemsUIView === itemTypes.DESSERTS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                {' '}
                                <span className="text-item">Desserts</span>
                            </p>
                        </div>
                        <div
                            onClick={() => this.selectUIView(itemTypes.DRINKS)}
                            className={`item_type ${itemsUIView === itemTypes.DRINKS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                {' '}
                                <span className="text-item">Drinks</span>
                            </p>
                        </div>

                        <div
                            onClick={() => this.selectUIView(itemTypes.COUPONS)}
                            className={`item_type ${itemsUIView === itemTypes.COUPONS ? 'selected' : 'not-selected'}`}
                        >
                            <p className="name">
                                {' '}
                                <span className="text-item">Coupon</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${base}__outlet_name`}>
                        {terminalInfo.data && terminalInfo.data.address.name && <p>{terminalInfo.data.address.name}</p>}
                    </div>
                </div>
            )
        );
    }
}
