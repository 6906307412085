import React from 'react';
import { inject, observer } from 'mobx-react';
import MainItems from './MainItems';
import AddonItems from './AddonItems';
import CouponCode from './CouponCode';
import DealCombo from './DealCombo';
const base = 'terminal-menu-items-ui';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
class MenuItemsUI extends React.Component {
    handleAddItemToCart = (item, isAddon) => {
        const { terminalMenuStore, terminalCartStore, history } = this.props;

        if (item.item_type == "Item") {
            terminalMenuStore.cartItem = item;
            history.push(`/terminal-mealSelection?id=${uuid()}`);

        } else {
            terminalMenuStore.handleAddItemToCart(item, isAddon);
            if (isAddon) {
                terminalCartStore.updateInCartOrder();
            }
        }
        // const { terminalMenuStore, history } = this.props;

    };

    componentDidMount() {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.triggerCardReaderStatusQuery();
    }

    renderContent = () => {
        const { terminalMenuStore, items } = this.props;
        const { itemsUIView, itemTypes } = terminalMenuStore;
        const mainItems = items.filter((item) => item.item_type === 'Item' || item.item_type === 'Combo');
        const addonItems = items.filter((item) => item.item_type === 'Addons');
        const comboPrice = terminalMenuStore.isComboPresent && terminalMenuStore.cheapestAddonItemPrice;

        if (itemsUIView == 'Coupons') {
            return <CouponCode />;
        } else if (itemsUIView == 'Deals') {
            return <DealCombo />;
        } else if (itemsUIView === itemTypes.MAINS) {
            return <MainItems items={mainItems} comboPrice={comboPrice} onAddToCart={this.handleAddItemToCart} />;
        } else {
            return <AddonItems items={addonItems} itemsUIView={itemsUIView} onAddToCart={this.handleAddItemToCart} />;
        }
    };

    render() {
        return <div className={base}>{this.renderContent()}</div>;
    }
}
export default withRouter(MenuItemsUI)